import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

import { ResponseAPI } from '../../domain/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class ApplyService {

  private readonly URL = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  findMenu( rolId: number ): Observable<ResponseAPI>{
    return this.http.get<ResponseAPI>(`${this.URL}/apply/${rolId}`);
  }

}
