import { Component } from '@angular/core';

@Component({
  selector: 'app-no-registers',
  templateUrl: './no-registers.component.html',
  styleUrls: ['./no-registers.component.scss']
})
export class NoRegistersComponent {

}
