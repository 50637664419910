import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { content } from "./shared/routes/routes";

import { AuthGuard } from './core/guards/auth.guard';
import { CheckToken } from './core/guards/check-token.guard';
import { FullComponent } from './shared/components/layout/full/full.component';
import { ContentHorizontalComponent } from './shared/components/layout/content-horizontal/content-horizontal.component';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'login', 
    pathMatch: 'full' 
  },
  {
    path: 'verification-email',
    loadChildren: () => import('../presentation/modules/verification-email/verification-email.module').then( (m) => m.VerificationEmailModule )
  },
  {
    path: 'view',
    component: ContentHorizontalComponent,
    loadChildren: () => import('../presentation/modules/view-certificate/view-certificate.module').then( (m) => m.ViewCertificateModule )
  },
  {
    path: 'login',
    canActivate: [ CheckToken ],
    loadChildren: () => import('../presentation/modules/auth/auth.module').then( (m) => m.AuthModule )
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: content
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
