import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from "@angular/router";

import { StorageService } from '../../shared/services/storage.service';

@Injectable({
  providedIn: "root",
})
export class CheckToken  {
  
  constructor(
    private router: Router,
    private storageService: StorageService
  ) { }

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {

    if( this.storageService.getToken() ){
      this.router.navigate(['/dashboard']);
      return false;
    }

    return true;

  }

}
