import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from "@angular/router";
import { tap } from "rxjs";

import { LoginService } from '../../../data/auth/login.service';

@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  
  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {

    return this.loginService.checkToken()
      .pipe(
        tap( token => {
          if( !token ) this.router.navigateByUrl('/login')
        })
      )
    
  }

}
