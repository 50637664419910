import { Component, OnInit } from "@angular/core";
import { UserSystem } from "src/app/domain/models/user.model";
import { LoginService } from "src/app/data/auth/login.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {

  user: UserSystem;

  constructor(
    public loginService: LoginService
  ) { }

  ngOnInit() {
    this.user = this.loginService.user;
  } 

  logout(){
    console.log("CLic in Logout");
    this.loginService.logout();
  }

}
