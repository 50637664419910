// -----------------------------------------------------------------------------------

//     developed by : Luis Alvarado
//     reference    : luisAlvarado4077@gmail.com

// -----------------------------------------------------------------------------------
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import Swal from 'sweetalert2';

import { LoginService } from '../../../../data/auth/login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  num: number = 1;

  constructor(
    private loginService: LoginService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    return next.handle(request)
      .pipe(
        catchError( (err) =>{
          if( err.status == 401 ){
            Swal.fire({
              title: 'Su sesión Caduco',
              text: 'Te enviaremos al Login, para que puedas ingresar con tus credenciales',
              icon: 'warning',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false
            }).then((result) => {
              if( result.isConfirmed ){
                this.loginService.logout();
                setTimeout(() => {
                  window.location.reload();
                }, 300);
              }
            })
          }else{
            let trying = localStorage.getItem('attemps');
            if( trying ) {
              this.num = parseInt(trying);
              if( this.num >= 3 ) this.loginService.logout();
            }else{
              localStorage.setItem('attemps', this.num.toString());
            }
  
            Swal.fire({
              title: `${this.num < 3 ? 'Opps' : 'Porfavor vuelva a Ingresar'}`,
              text: `${this.num < 3 ? 'Algo salio mal, porfavor vuelva a intentarlo' : ''}`,
              icon: `${this.num < 3 ? 'error' : 'warning'}`,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false
            }).then( (result) =>{
              if(result && this.num < 3){
                this.num = this.num+1;
                localStorage.setItem('attemps', this.num.toString());
                window.location.reload();
              }
            })
          }
          return of();
        })
      )
  }
}
