import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class MessagesValidation {

  // developed by luisAlvarado4077@gmail.com
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      'required': 'Este dato es requerido',
      'onlyNumbers': 'Solo es permitido insertar numeros',
      'maxlength': `Este campo no puede tener mas de ${validatorValue.requiredLength} caracteres`,
      'minlength': `Este campo tiene que tener como minimo ${validatorValue.requiredLength} caracteres`
    };

    return config[validatorName as keyof Object];
  }

  static validateNumber( control: any ){

    if (control.value.match(/^[0-9]*$/)) {
      return null;
    }else{
      return { 'onlyNumbers': true };
    }

  }

}
