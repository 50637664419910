<div [ngClass]="layout.config.settings.layout_type">
  <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layoutClass">

    <!-- <div class="page-wrapper ng-tns-c3506202530-0 horizontal-wrapper" id="canvas-bookmark"> -->

    <app-header [horizontal]="true"></app-header>

    <div class="page-body-wrapper">
      <!-- <div class="sidebar-wrapper" [attr.icon]="layout.config.settings.icon" [class.close_icon]="navServices.collapseSidebar">
        <app-sidebar></app-sidebar>
      </div> -->
      <div class="page-body">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>

      <footer class="footer">
        <app-footer></app-footer>
      </footer>

    </div>

  </div>
</div>

<div class="bg-overlay1"></div>
