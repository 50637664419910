<div class="media profile-media">
  <!-- <img class="b-r-10" src="assets/images/dashboard/profile.png" alt=""> -->
  <img class="b-r-10" [src]="user.image" alt="" class="w-34 b-r-23">
  <img src="assets/images/user-general.png" alt="" class="w-34 b-r-23" *ngIf="!user.image">

  <div class="media-body"><span>{{user.name}} {{user.fatherLastName}} {{user.motherLastName}}</span>
    <p class="mb-0 font-roboto">{{user.rol.name}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Mi Perfil </span></a></li> -->
  <li (click)="logout()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Salir</span></li>
</ul>