import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReloadComponent } from './reload/reload.component';
import { NoRegistersComponent } from './no-registers/no-registers.component';

@NgModule({
  declarations: [
    ReloadComponent,
    NoRegistersComponent,
  ],
  exports: [
    ReloadComponent,
    NoRegistersComponent
  ],
  imports: [
    CommonModule
  ]
})
export class GlobalComponentsModule { }
