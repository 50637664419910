import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { MessagesValidation } from '../../validators/messages-validation';

@Component({
  selector: 'control-messages',
  template: `<div class="text-ctr" *ngIf="errorMessage !== null">{{errorMessage}}</div>`
})
export class FormControlMessageComponent {

  // developed by luisAlvarado4077@gmail.com
  @Input() control: FormControl = new FormControl;
  constructor() { }

  get errorMessage() {
    for (let propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.invalid) {
        return MessagesValidation.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }
    return null;
  }

}
