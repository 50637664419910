import { Component, ViewEncapsulation, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';

import { MenuOpt } from 'src/app/domain/models/menu.model';

import { ApplyService } from 'src/app/data/apply/apply.service';
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/data/auth/login.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  public iconSidebar;
  menuItems: Menu[] = [];

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  menus: Menu[];

  constructor(
    private router: Router,
    public navServices: NavService,
    public layout: LayoutService,
    private applyService: ApplyService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.findMenu();
  }

  findMenu(){

    this.applyService.findMenu( this.loginService.user.rol.id )
      .subscribe( res =>{
        if( res.statusCode < 400 ){
          let menus = res.data.map( e => e.menu );
          this.menuItems = menus;
        }else{
          Swal.fire('Atencion', res.message, 'warning');
        }
      })

  }

  // load(){
    
  //   this.loginService.checkToken()
  //     .subscribe( res =>{
  //       this.menuItems = [
  //         // {
  //         //   headTitle1: "General",
  //         // },
  //         { path: "/escritorio", title: "Escritorio", icon: "home", type: "link" },
  //         { path: "/empresas", title: "Empresas", icon: "sample-page", type: "link" },
  //         { path: "/empleos", title: "Empleos", icon: "sample-page", type: "link" },
  //         { path: "/profesional/empleos", title: "Mis Empleos", icon: "sample-page", type: "link" },
  //       ];

  //       // this.menus = menuT;
  //       // this.menuItems = this.menus;

  //       this.loadMenu();

  //     })

  // }

  loadMenu(){

    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      })
    })

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
}
