<swiper [direction]="'vertical'" [loop]="true" [autoHeight]="true" [allowTouchMove]="false" [autoplay]="{ delay: 1000, disableOnInteraction: false }" [pagination]="false" [navigation]="false">
  <!-- <ng-template data-swiper-autoplay="5000" swiperSlide>
    <div class="d-flex h-100">
      <img src="assets/images/warning-2.gif" class="img-pst" alt="gif" />
      <h6 class="mb-0 f-w-400 text-line-1"><span class="f-light text-pst">{{position}}</span></h6>
    </div>
  </ng-template> -->
  <!-- <ng-template data-swiper-autoplay="2500" swiperSlide *ngIf="authService.positions.length > 1">
    <div class="d-flex h-100">
      <img src="assets/images/warning-2.gif" alt="gif" class="m-t--1"/>
      <a class="ms-1" href="https://1.envato.market/3GVzd" target="_blank">No olvides cambiar tu cargo!</a>
    </div>
  </ng-template> -->
</swiper>
