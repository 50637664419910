import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

import { StorageService } from '../../presentation/shared/services/storage.service';
import { ResponseAPI } from '../../domain/models/response.model';
import { UserSystem } from 'src/app/domain/models/index.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  private readonly URL = environment.API_URL;

  user: UserSystem;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
  ) { }

  signIn( user: any ): Observable<any>{

    return this.http.post<ResponseAPI>(`${this.URL}/login`, user)
      .pipe(
        map( res =>{
          this.storageService.saveToken(res.data);
          return res;
        }),
        catchError( err => of(false) )
      ); 

  }

  signInGoogle( token: string ): Observable<any>{

    let data = { 'token': token };

    return this.http.post<ResponseAPI>(`${this.URL}/login/google`, data)
      .pipe(
        map( res =>{
          this.storageService.saveToken(res.data);
          return res;
        }),
        catchError( err => of(false) )
      );

  }

  checkToken(): Observable<ResponseAPI>{
    return this.http.get<ResponseAPI>(`${this.URL}/login`)
      .pipe(
        tap( res => this.user = res.data )
      )
  }

  logout(){
    this.router.navigate(['/login']);    
    this.storageService.removeToken();
    
    setTimeout(() => {
      window.location.reload();
    }, 500);

  }

}
