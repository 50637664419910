import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../modules/dashboard/dashboard.module').then( (m) => m.DashboardModule )
  },
  {
    path: 'user',
    loadChildren: () => import('../../modules/user/user.module').then( (m) => m.UserModule )
  },
  {
    path: 'certificate',
    loadChildren: () => import('../../modules/certificate/certificate.module').then( (m) => m.CertificateModule )
  },
  {
    path: 'social-networks',
    loadChildren: () => import('../../modules/social-network/social-network.module').then( (m) => m.SocialNetworkModule )
  }
];
